const links = [
  {
    label: "Home",
    url: "/",
  },
  {
    label: "Work",
    url: "/work",
  },
  {
    label: "Contact",
    url: "/contact",
  },
//   {
//     label: "Social",
//     url: "/social",
//   },
]
export default links
